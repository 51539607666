import React, { useRef, useState} from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import emailjs from '@emailjs/browser';
import {Link} from 'react-router-dom'
import {useNavigate} from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import './css/form.css'

const schema = yup.object().shape({
    name: yup.string().max(60,'Imię nie może być dłuższe niż 60 znaków').required("Podaj swoje imię"),
    email: yup.string().email('Podaj poprawny adres mail').required("Adres email jest obowiązkowy"),
    phone: yup.number().typeError('Podaj poprawny numer telefonu').positive().integer().required('Wprowadź poprawny numer biletu'),
    message: yup.string(),
    acceptTerms: yup.bool().oneOf([true], 'Musisz wyrazić zgodę aby przesłać formularz')
  });

const Form = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const form = useRef();
    const navigate = useNavigate();

    const captchaRef = useRef(null)
    const [captchaVeryfi, setCaptchaVeryfi] = useState(false)
    const [captchaError, setCaptchaError] = useState(false)
    const [loadRecaptcha, setLoadRecaptcha] = useState(false);

    function onChange(value) {
       //console.log("Captcha value:", value);
       setCaptchaVeryfi(true)
     }
    let termsValue;
   //const onSubmit = data => console.log(data);
    const onSubmit = data => {
        setLoadRecaptcha(true)
         if(data.acceptTerms===true){
            termsValue = 'Tak, Zgadzam się na przetwarzanie moich danych osobowych. Zapoznałem się z polityką prywatności i akcetuję jej warunki '
         }
        if(!captchaVeryfi){
            setCaptchaError(true)
        } else {
            navigate('/thank-you', 
             {state:{
                name:data.name, 
                email:data.email,
                phone:data.phone,
                message:data.message,
                acceptTerms: termsValue
             }})
     emailjs.sendForm(
         'service_68uu6zf', 
         'template_f421ddn', 
         form.current, 
         'gfb8GUSL3r5b1Opt7'
         )
       .then((result) => {
           console.log(result.text);
       }, (error) => {
           console.log(error.text);
       });
     reset();

         }
     };

 
  return (
    <div>
        <form ref={form} className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group'>
                <label htmlFor='name'>Imię</label>
                <input id="name" type="text" {...register("name")} />
                {errors.name && <span className="form-error">{errors.name?.message}</span>}
            </div>
            <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input id="email" type="text" {...register("email")} />
                {errors.email && <span className="form-error">{errors.email?.message}</span>}
            </div>
            <div className='form-group'>
                <label htmlFor='phone'>Telefon</label>
                <input id ="phone" type="phone" {...register("phone")} />
                {errors.phone && <span className="form-error">{errors.phone?.message}</span>}
            </div>
            <div className='form-group'>
                <label htmlFor='message'>Wiadomość</label>
                <textarea rows='6' id="message" {...register("message", {})} />
            </div>
            <div className='acceptTerms'>
                <input type="checkbox" value="true" {...register('acceptTerms')} id="acceptTerms" />
                <label htmlFor="acceptTerms">Zgadzam się na przetwarzanie moich danych osobowych. Zapoznałem się z <Link className='link link-secondary' to='polityka-prywatnosci'>polityką prywatności</Link> i akcetuję jej warunki</label>
            </div>
            {errors.acceptTerms && <span className="form-error">{errors.acceptTerms?.message}</span>}
            {loadRecaptcha ? (
                    <ReCAPTCHA className='captcha'
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    // sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' 
                    ref={captchaRef} 
                    onChange={onChange}
                    />
            ) : null}
           
            {captchaError && <span className="form-error">Zaznacz pole reCAPTCHA</span>}
            <button type="submit" className='btn form-btn'>Wyślij wiadomość</button>
            {/* <button onClick={() => reset()} type="button" className='btn form-btn'>| &nbsp; Reset &nbsp; |</button> */}
        </form>
    </div>
  )
}

export default Form
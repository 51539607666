import React from 'react'
import './css/offer.css'
import SectionHeader from './SectionHeader'
import {FaWordpressSimple, FaMobileAlt, FaChartBar} from 'react-icons/fa';
import {BsGridFill} from 'react-icons/bs';
import {Link} from 'react-scroll';

import image from '../assets/img/kbsilesia.webp'

const Offer = () => {
  return (
    <section id='offer' data-aos="fade-up"  data-aos-offset="300" data-aos-duration="1500">
        <div className='container'>
            <SectionHeader title='Nasza Oferta.'/>
            <div className='offer-intro'>Projektujemy i tworzymy innowacyjne strony internetowe w oparciu o system WordPress. <br/>Wykorzystując możliwości tego systemu oraz nasze doświadczenie dopasowujemy ofertę do potrzeb i oczekiwań Klientów.</div>
           <div className='grid-2 offer-grid'>
            <div className='offer-icons'>
                <div className='offer-icons-item'>
                    <FaWordpressSimple className='offer-icon'/>
                    <p>Przyjazny panel administracyjny, łatwa intuicyjna obsługa strony, edycja treści za pomocą edytora Gutenberg. </p>
                </div>
                <div className='offer-icons-item'>
                    <FaMobileAlt className='offer-icon'/>
                    <p>Dbamy o responsywność stron internetowych, by ich zawartość dopasowywała się do każdego urządzenia, na którym aktualnie jest wyświetlana.</p>
                </div>
                <div className='offer-icons-item'>
                    <BsGridFill className='offer-icon'/>
                    <p>Cenimy sobie indywidualne podejście do Klientów, dlatego zapewniamy im unikalny projekt graficzny.</p>
                </div>
                <div className='offer-icons-item'>
                    <FaChartBar className='offer-icon'/>
                    <p>Łatwość optymalizacji strony pod wyszukiwarki dzięki wykorzystaniu wtyczkek wspierających pozycjonowanie.</p>
                </div>
                
            </div>
            <div className='offer-img'>
                    <img src={image} alt='oferta-singraph'/>
            </div> 
           </div>
           
            <Link
             to="contact" 
             spy={true} 
             smooth={true} 
             duration={500}>
                <button className='btn offer-btn'>Zapytaj o szczegóły oferty</button>
            </Link>
        </div>
    </section>
    
  )
}

export default Offer
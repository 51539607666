import React from 'react'
import SectionHeader from '../components/SectionHeader'
import { useLocation } from 'react-router-dom'

const ThankYouPage = () => {
  const { state } = useLocation();
  return (
        <div>
            <div className='content-area' >
              <div className='container'>
                  <SectionHeader title='Dziękujemy za kontakt z nami'/>
                  <p>Dziękujemy za przesłanie zgłoszenia. Po przeanalizowaniu otrzymanych informacji, skontaktujemy się na podane w zgłoszeniu dane kontaktowe. </p>
                  <p>Poniżej podsumowanie Twojego zgłoszenia: </p>
                 <ul className='page-list'>
                   <li>Imię: {state?.name}</li>
                   <li>Email: {state?.email}</li>
                   <li>Telefon: {state?.phone}</li>
                    <li>Wiadomość: {state?.message}</li>
                    <li>Zgoda na przetwarzanie danych: {state?.acceptTerms}</li>
                 </ul>
                  <p>Zespół sinGRAPH</p>
              </div>
            </div>
        </div>
  )
}

export default ThankYouPage
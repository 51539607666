import React, {useState, useEffect} from 'react'
import './css/navbar.css'
import logo from "../assets/img/singraph.webp"
import {AiOutlineMenu, AiOutlineClose, AiOutlineArrowUp} from 'react-icons/ai';
import {Link, animateScroll as scroll } from 'react-scroll';
import {Link as PageLink, useLocation} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false)
  const location = useLocation();

  const handleMobile = () => {
    setIsMobile(!isMobile)
  }

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  
  const [isScroll, setIsScroll] = useState(false)

  useEffect(()=>{
    window.addEventListener("scroll", () =>{
      if(window.scrollY > 500){
        setIsScroll(true)
      } else {
        setIsScroll(false)
      }
    })
  }, [])

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

const scrollWithOffsetMobile = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -50; 
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}
  return (
    <div className='navbar-section'>
      <div className='container'>
       <div className='navbar'>
          <div className='navbar-logo'>
            <PageLink to='/'><img src={logo} alt='singraph-logo' /></PageLink>
          </div>
          <div className='navbar-menu'>
           <ul className='navbar-nav'>
             <li>
             {
            location.pathname === '/' ? 
            <Link 
                activeClass="active" 
                to="about" 
                spy={true} 
                smooth={true} 
                offset={-120}
                duration={500}>
                O nas
            </Link>
                 :  
            <HashLink  
                 className='link'
                 to="/#about" 
                 scroll={el => scrollWithOffset(el)}
            >
                O nas
            </HashLink>
          }
            </li>
            <li>
              {
            location.pathname === '/' ? 
              <Link 
                  activeClass="active" 
                  to="offer" 
                  spy={true} 
                  smooth={true} 
                  offset={-120}
                  duration={500}>
                  Oferta
              </Link>
                  :
              <HashLink  
                  className='link'
                  to="/#offer" 
                  scroll={el => scrollWithOffset(el)}
              >
                Oferta
              </HashLink> 
              }
              </li>
              <li>
                 {
               location.pathname === '/' ? 
              <Link 
                  activeClass="active" 
                  to="realization" 
                  spy={true} 
                  smooth={true} 
                  offset={-120}
                  duration={500}>
                Realizacje
              </Link>
                  :
              <HashLink  
                  className='link'
                  to="/#realization" 
                  scroll={el => scrollWithOffset(el)}
              >
                Realizacje
              </HashLink> 
                 }
                </li>
              <li>
              {
                location.pathname === '/' ? 
            <Link 
                  activeClass="active" 
                  to="contact" 
                  spy={true} 
                  smooth={true} 
                  offset={-120}
                  duration={500}>
                  Kontakt
            </Link>
                  : 
            <HashLink  
                  className='link'
                  to="/#contact" 
                  scroll={el => scrollWithOffset(el)}
            >
                Kontakt
            </HashLink> 
                  }
              </li>
           </ul>
          </div>
        <div className='mobile-menu'>
          <div className='navbar-icon' onClick={handleMobile}>
            {isMobile ? <AiOutlineClose />  : <AiOutlineMenu /> }
          </div>
          <ul className={isMobile ? 'mobile-nav active' : 'mobile-nav'}>
          {
            location.pathname === '/' ? 
            <li><Link 
                activeClass="active" 
                to="about" 
                spy={true} 
                smooth={true} 
                offset={-50}
                duration={500}>
               O nas 
            </Link></li>
            :
            <li><HashLink  
              className='link'
              to="/#about" 
              scroll={el => scrollWithOffsetMobile(el)}
           >
             O nas
            </HashLink></li>
          }
          {
            location.pathname === '/' ? 
            <li><Link 
                activeClass="active" 
                to="offer" 
                spy={true} 
                smooth={true} 
                offset={-50}
                duration={500}>
               Oferta
            </Link></li>
            :
            <li><HashLink  
              className='link'
              to="/#offer" 
              scroll={el => scrollWithOffsetMobile(el)}
           >
              Oferta
            </HashLink></li>
          }
              {
            location.pathname === '/' ? 
            <li><Link 
                activeClass="active" 
                to="realization" 
                spy={true} 
                smooth={true} 
                offset={-50}
                duration={500}>
                Realizacje
            </Link></li>
            :
            <li><HashLink  
              className='link'
              to="/#realization" 
              scroll={el => scrollWithOffsetMobile(el)}
           >
              Realizacje
            </HashLink></li> 
          }
                {
            location.pathname === '/' ? 
            <li><Link 
                activeClass="active" 
                to="contact" 
                spy={true} 
                smooth={true} 
                offset={-50}
                duration={500}>
                Kontakt
            </Link></li>
            :
            <li><HashLink  
              className='link'
              to="/#contact" 
              scroll={el => scrollWithOffsetMobile(el)}
           >
              Kontakt
            </HashLink> </li>
          }
          </ul>
        </div>
        </div>
      </div>
      <div className={isScroll ? 'scroll-active scroll-top ' : 'scroll-top'} onClick={scrollToTop}>
        <AiOutlineArrowUp className='scroll-icon'/>
      </div>
    </div>
  )
}
export default Navbar
import React from 'react'
import SectionHeader from './SectionHeader'
import './css/about.css'
import image from '../assets/img/saled.webp'

const About = () => {
  return (
    <section className='about-section' id='about'>
        <div className='container'>
            <div className='grid-2 about'>
                <div className='about-img'>
                    <img src={image} alt='o-nas-singraph'/>
                </div>  
                <div className='about-intro' data-aos="fade-left"  data-aos-offset="300" data-aos-duration="1500">
                <SectionHeader title='O Nas.'/>
                Od początku swojego istnienia, firma sinGRAPH dostarcza swoim Klientom kompleksowe usługi i rozwiązania marketingowe. <br/><br/>
Każde nowe zadanie traktujemy w pełni indywidualnie, ponieważ zależy nam na dopasowaniu oferty do specyfiki branży, którą reprezentują nasi Klienci. 
<br/><br/>Dzięki tej elastyczności i kreatywności, jesteśmy w stanie sprostać nawet tym najbardziej wymagającym.
                </div>    
            </div>
        </div>
    </section>
  )
}

export default About
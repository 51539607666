import React from 'react'
import './css/footer.css'
import logo from '../assets/img/footer-logo.webp'
import {FaPhoneAlt, FaEnvelope} from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='container'>
                <div className='footer-text'>
                    <img src={logo} alt='singraph-logo' />
                    <ul className='page-list'>
                        <li>ul. Krakowska 7</li>
                        <li>41-400 Mysłowice</li>
                        <li><FaPhoneAlt className='footer-icon'/> +48 32 223 87 80</li>
                        <li><FaEnvelope className='footer-icon'/> info@singraph.pl</li>
                    </ul>
                </div>
            </div>
<script>
  AOS.init();
</script>
        </div>
 
  )
}

export default Footer
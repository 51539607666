import React from 'react'
import './css/sectionHeader.css'

const SectionHeader = ({title}) => {
  return (
    <div>
      <h2 className='section-header'>{title}</h2>
    </div>
  )
}

export default SectionHeader
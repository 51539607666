import React from 'react'
import SectionHeader from '../components/SectionHeader'

const PrivacyPage = () => {
  return (
    <div>
        <div className='content-area' >
            <div className='container'>
            <SectionHeader title='Polityka Prywatności.'/>
                
              <div>
                <h3>Informacje ogólne</h3>
<br/>
Właścicielem serwisu i Administratorem powierzonych danych osobowych jest firma sinGRAPH będąca agencją interaktywną. <br/>Firma świadczy profesjonalne usługi tworzenia serwisów internetowych, tworzenia projektów graficznych oraz świadcząca usługi hostingu współdzielonego oraz zajmująca się pozycjonowaniem i optymalizacją SEO.<br/>
<br/>    
<b>Dane firmowe</b><br/>  
     sinGRAPH Renata Wnuk<br/>  
     ul. Krakowska 7<br/>  
     41-400 Mysłowice<br/>  
     NIP 2220152204<br/>  <br/>  
     <b>Dane kontaktowe:</b><br/>  
     info@singraph.pl<br/>  
     tel: 32 223 87 80
     <br/>  <br/>  
     Przeglądając stronę singraph.pl akceptujesz zasady zawarte w Polityce Prywatności znajdujące się na tej stronie. Korzystając z usług oferowanych przez sinGRAPH akceptujesz zasady zawarte w Polityce Prywatności znajdujące się na tej stronie. Serwis zastrzega sobie prawo do wprowadzania zmian w Polityce Prywatności.
     <br/> <br/> 
     <h3>Dane osobowe</h3>
     <br/> 
     Użytkownicy korzystający ze strony singraph.pl sami decydują, czy chcą udostępnić swoje dane osobowe poprzez formularze kontaktowe, kontaktowy adres e-mail oraz formularze zamówieniowe.<br/> 
     <br/> Gromadzimy w ten sposób takie dane osobowe jak: imię nazwisko, nazwę firmy, adres, adres email, numer telefonu kontaktowego, NIP. 
     <br/> <br/> Dane te są wykorzystywane i niezbędne jedynie w celach realizacji usług zamówionych przez Klienta.
     <br/> <br/> 
     Użytkownicy zamierzający korzystać z naszych usług, zostaną poproszeni o podanie nam swoich danych osobowych.
    Podanie danych osobowych jest warunkiem zawarcia umowy i realizacji zamówionych usług. Podanie danych osobowych jest dobrowolne, jednak konsekwencją ich niepodania będzie brak możliwości zawarcia umowy i realizacji usług.
     <br/> <br/> 
     Dane użytkowników, po ich wcześniejszej zgodzie, mogą być wykorzystywane w celach marketingowych np.: przedstawienie aktualnych ofert, promocji. Zgoda na otrzymywanie informacji marketingowych jest dobrowolna i nie ma wpływu na realizowane usługi na rzecz Klienta.
     <br/> <br/> 
     Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne i techniczne zgodne z właściwymi przepisami prawa (zwłaszcza z RODO - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE), w tym stosujemy szyfrowanie połączenia za pomocą certyfikatu SSL.
     <br/> <br/> 
     <b>Cele przetwarzania danych</b>
     <br/> <br/> 
     W zależności od tego, na co się zdecydujesz, może to być:
     <ul className='page-list'>
      <li>świadczenie poszczególnych usług oferowanych przez singraph,</li>
      <li>marketing bezpośredni oferowanych usług, inny niż newsletter tj. oparty na twojej zgodzie na otrzymanie od nas oferty handlowej dotyczącej realizowanych usług,</li>
      <li>wysyłanie newslettera tj. świadczenie usług drogą elektroniczną.</li>
     </ul>
     <br/>  
  
     Twoje dane będziemy przetwarzać tylko przez okres jaki jest niezbędny do tego, aby osiągnąć założony cel przetwarzania danych:
     <ul className='page-list'>
        <li>przez okreś świadczenia danej usługi lub</li>
        <li>do czasu gdy przestanie ciążyć na nas obowiązek prawny, zobowiązujący nas do przetwarzania twoich danych lub</li>  
        <li>cofniesz zgodę na przetwarzanie danych, jeśli była ona podstwą przetwarzania
        </li>
     </ul>
     <br/>    
     Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo przetwarzający, z którymi ściśle współpracujemy. W związku z realizacją usług dla naszych Klientów, korzystamy z usług innych podmiotów, które świadczą dla nas usługi wspierające naszą działalność. Twoje dane mogą być przekazywane, wyłącznie w celach wskazanych w niniejszej polityce:
   
     <ul className='page-list'>
      <li>firmom hostingowym zapewniającym nam miejsce na serwerach,</li>
      <li>osobom współpracującym z nami na podstawie umów cywilnoprawnych, wspierającym naszą bieżącą działalność,
</li>
      <li> dostawcy oprogramowania do wystawiania faktur,</li>
      <li>podmiotom świadczącym obsługę księgową i rachunkową.</li>
     </ul>
     <br/>  
     Użytkownikowi przysługuje:
     <ul className='page-list'>
      <li>prawo do żądania od Administratora dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania,</li>
      <li>prawo do wniesienia sprzeciwu wobec przetwarzania,</li>
      <li>prawo do przenoszenia danych</li>
      <li>prawo do wniesienia skargi do organu nadzorczego.</li>
     </ul>
     <br/> 
     Zgłoszenia realizacji swoich praw można dokonywać w następujący sposób:
     <br/> 
         pisemnie na adres:  <br/> 
         sinGRAPH Renata Wnuk  <br/> 
         ul. Krakowska 7  <br/> 
         41-400 Mysłowice  <br/> 
         mailowo na adres: info@singraph.pl.
         <br/>  <br/> 
         <h3>Cookies</h3>  <br/> 
     W ramach naszego serwisu stosujemy pliki cookies.
     <br/>  <br/> 
     Pliki cookies są to niewielkie pliki tekstowe przechowywane w pamięci przeglądarki lub zapisywane na dysku urządzenia. Przeznaczone są do korzystania ze stron serwisu. Przede wszystkim zawierają nazwę strony internetowej swojego pochodzenia, swój unikalny numer, czas przechowywania na urządzeniu końcowym. Pliki cookies gromadzone są w celach statystycznych i nie umożliwiają identyfikacji konkretnych użytkowników.
     <br/>  <br/> 
     Gromadzone informacje dotyczą adresu IP, typu wykorzystywanej przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy usług internetowych, informacji o czasie i dacie, lokalizacji oraz informacji przesyłanych do witryny za pośrednictwem formularza kontaktowego.
     <br/>  <br/> 
     Do czego wykorzystujemy pliki cookies:
     <ul className='page-list'>
      <li>do dopasowania zawartości strony internetowej do indywidualnych preferencji użytkownika, pliki te rozpoznają jego urządzenie, aby zgodnie z jego preferencjami wyświetlić stronę;</li>
      <li>do przygotowywania statystyk pomagających poznaniu preferencji i zachowań użytkowników, analiza tych statystyk jest anonimowa i umożliwia dostosowanie zawartości i wyglądu serwisu do panujących trendów, statystyki stosuje się też do oceny popularności strony</li>
     </ul>
     <br/> 
     
     Użytkownik może w każdej chwili dokonać zmiany ustawień swojej przeglądarki, aby zablokować obsługę plików cookies. Inne dostępne opcje można sprawdzić w ustawieniach swojej przeglądarki internetowej. Zmiany ustawień w przeglądarce internetowej użytkownika mogą ograniczyć dostęp do niektórych funkcji strony internetowej.
      
              </div>
            </div>
        </div>
    </div>
  )
}

export default PrivacyPage
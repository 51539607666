import React from 'react'
import './css/hero.css'
import {Link} from 'react-scroll';

const Hero = () => {
  return (
    <div className='hero'>
        <div className='container'>
            <div className='grid-2 hero-inner'>
                <div className='hero-text' data-aos="fade-right"  data-aos-offset="300" data-aos-duration="1500">
                    <h1>Firmowe Strony Internetowe WordPress</h1>
                    <ul>
                      <li>Nowoczesne</li>
                      <li>Przejrzyste</li>
                      <li>Funkcjonalne</li>
                    </ul>
                    <Link 
                     to="contact" 
                     spy={true} 
                     smooth={true} 
                     duration={500}>
                      <button className='btn'>Zapytaj o wycenę</button></Link>
                </div>
                <div className='hero-img'></div>
            </div>
        </div>
    </div>
  )
}

export default Hero
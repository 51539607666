import React, {useEffect} from 'react'
import Hero from "../components/Hero";
import Realizations from "../components/Realizations";
import About from "../components/About";
import Offer from "../components/Offer";
import Contact from "../components/Contact";
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomePage = () => {
  useEffect(() => {
    AOS.init({once: true});
  }, [])

  return (
    <div>
         
          <Hero/> 
          <About/> 
          <Offer/> 
          <Realizations/>
          <Contact/>
    </div>
  )
}

export default HomePage
import React from 'react'
import SectionHeader from './SectionHeader'
import './css/realizations.css'

import item1 from '../assets/img/hutaolawa.webp'
import item2 from '../assets/img/saled.webp'
import item3 from '../assets/img/fotobudka.webp'
import item4 from '../assets/img/silesiasa.webp'
import item5 from '../assets/img/opera.webp'
import item6 from '../assets/img/happy.webp'
import item7 from '../assets/img/kbsilesia.webp'
import item8 from '../assets/img/is-kk.webp'
import item9 from '../assets/img/starstone.webp'

const Realizations = () => {
  return (
    <section id='realization'>
        <SectionHeader title='Najnowsze Realizacje.'/>
        <div className='container'>
          <div className='realization-items grid-3' data-aos="zoom-out"  data-aos-offset="300" data-aos-duration="1500" data-aos-once='true' >
            <div className='realization-item'>
              <a href='https:hutaolawa.pl'>
                <img className='realization-image' src={item1} alt='singraph-realizacje-huta-olawa'/>
                <h3>hutaolawa.pl</h3>
              </a>
            </div>
            <div className='realization-item'>
              <a href='https:saled.pl'>
                <img className='realization-image' src={item2} alt='singraph-realizacje-saled'/>
                <h3>saled.pl</h3>
              </a>
            </div>
            <div className='realization-item'>
              <a href='http://fotobudka360slask.pl/'>
                <img className='realization-image' src={item3} alt='singraph-realizacje-fotobudka'/>
                <h3>fotobudka360slask.pl</h3>
              </a>
            </div>
            <div className='realization-item'>
              <a href='https://silesiasa.pl/'>
                <img className='realization-image' src={item4} alt='singraph-realizacje-silesiasa'/>
                <h3>silesiasa.pl</h3>
              </a>
            </div>
            <div className='realization-item'>
              <a href='https://operaquartet.com/'>
                <img className='realization-image' src={item5} alt='singraph-realizacje-operaquartet'/>
                <h3>operaquartet.com</h3>
              </a>
            </div>
            <div className='realization-item'>
              <a href='https://happy.edu.pl/'>
                <img className='realization-image' src={item6} alt='singraph-realizacje-happy-edu'/>
                <h3>happy.edu.pl</h3>
              </a>
            </div>
            <div className='realization-item'>
              <a href='https://kbsilesia.pl/'>
                <img className='realization-image' src={item7} alt='singraph-realizacje-kbsilesia'/>
                <h3>kbsilesia.pl</h3>
              </a>
            </div>
            <div className='realization-item'>
              <a href='https://is-kk.pl/'>
                <img className='realization-image' src={item8} alt='singraph-realizacje-iskk'/>
                <h3>is-kk.pl</h3>
              </a>
            </div>
            <div className='realization-item'>
              <a href='https://starstone.pl/'>
                <img className='realization-image' src={item9} alt='singraph-realizacje-starstone'/>
                <h3>starstone.pl</h3>
              </a>
            </div>
            
          </div>
        </div>
        
        <div className='realization-bg'></div>
       
    </section>
  )
}

export default Realizations
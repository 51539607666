import React from 'react'
import SectionHeader from './SectionHeader'
import Form from './Form'

const Contact = () => {
  return (
    <section data-aos="fade-right"  data-aos-offset="300" data-aos-duration="1500">
       <div className='container' id='contact'>
         <SectionHeader title='Napisz Do Nas.'/>
         <Form/>
        </div>
    </section>
   
  )
}

export default Contact
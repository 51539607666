import HomePage from "./pages/HomePage";
import { Routes, Route } from "react-router-dom";
import ThankYouPage from './pages/ThankYouPage';
import PrivacyPage from "./pages/PrivacyPage";
import ToTopOfPage from "./helpers/ToTopOfPage";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="App">
     
      <ToTopOfPage>
        <Navbar/>
        <Routes>
          <Route path="/" element={<HomePage/>}></Route>
          <Route path='/thank-you' element={<ThankYouPage /> }></Route>
          <Route path='/polityka-prywatnosci' element={<PrivacyPage /> }></Route>
        </Routes>
        <Footer/>
       </ToTopOfPage>
    
    </div>
  );
}

export default App;
